import * as S from "./styles";

import { Col, Row } from "antd";

import Slide from "react-reveal/Slide";
import SvgIcon from "../../../common/SvgIcon";
import { withTranslation } from "react-i18next";

const LeftContentBlock = ({
  icon,
  title,
  content,
  section,
  t,
  id,
  size,
  titleSize,
}) => {
  return (
    <S.LeftContentBlock>
      <Row type="flex" justify="space-between" align="middle" id={id}>
        <Col lg={11} md={11} sm={12} xs={24}>
          <Slide left>
            <SvgIcon
              src={icon}
              className="about-block-image"
              width={size}
              height={size}
            />
          </Slide>
        </Col>
        <Col lg={11} md={11} sm={11} xs={24}>
          <Slide right>
            <S.ContentWrapper>
              {titleSize || false ? (
                <htitle>{t(title)}</htitle>
              ) : (
                <h6>{t(title)}</h6>
              )}
              {content.map((i) => {
                return (
                  <S.Content>
                    {titleSize || false ? (
                      <ptitle>{t(i)}</ptitle>
                    ) : (
                      <p>{t(i)}</p>
                    )}
                  </S.Content>
                );
              })}
              <S.ServiceWrapper>
                <Row type="flex" justify="space-between">
                  {section &&
                    typeof section === "object" &&
                    section.map((item, id) => {
                      return (
                        <Col key={id} span={11}>
                          <SvgIcon src={item.icon} width="60px" height="60px" />
                          <S.MinTitle>{t(item.title)}</S.MinTitle>
                          <S.MinPara>{t(item.content)}</S.MinPara>
                        </Col>
                      );
                    })}
                </Row>
              </S.ServiceWrapper>
            </S.ContentWrapper>
          </Slide>
        </Col>
      </Row>
    </S.LeftContentBlock>
  );
};

export default withTranslation()(LeftContentBlock);
