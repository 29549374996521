import * as S from "./styles";

import { Col, Row } from "antd";

import Button from "../../../common/Button";
import Slide from "react-reveal/Slide";
import SvgIcon from "../../../common/SvgIcon";
import { withTranslation } from "react-i18next";

const RightBlock = ({
  title,
  content,
  button,
  icon,
  t,
  id,
  size,
  titleSize,
}) => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <S.RightBlockContainer>
      <Row type="flex" justify="space-between" align="middle" id={id}>
        <Col lg={11} md={11} sm={11} xs={24}>
          <Slide left>
            <S.ContentWrapper>
              {titleSize || false ? (
                <htitle>{t(title)}</htitle>
              ) : (
                <h6>{t(title)}</h6>
              )}
              {content.map((i) => {
                return (
                  <S.Content>
                    {titleSize || false ? (
                      <ptitle>{t(i)}</ptitle>
                    ) : (
                      <p>{t(i)}</p>
                    )}
                  </S.Content>
                );
              })}
              <S.ButtonWrapper>
                {button &&
                  typeof button === "object" &&
                  button.map((item, id) => {
                    return (
                      <Button
                        key={id}
                        color={item.color}
                        width="true"
                        onClick={() => scrollTo("about")}
                      >
                        {t(item.title)}
                      </Button>
                    );
                  })}
              </S.ButtonWrapper>
            </S.ContentWrapper>
          </Slide>
        </Col>
        <Col lg={11} md={11} sm={12} xs={24}>
          <Slide right>
            <SvgIcon
              src={icon}
              className="about-block-image"
              width={size}
              height={size}
            />
          </Slide>
        </Col>
      </Row>
    </S.RightBlockContainer>
  );
};

export default withTranslation()(RightBlock);
